import { getUserTierFromPlan } from "@/lib/getUserTierFromPlan";
import { pushEvent } from "@/lib/gtm";
import { getDevice } from "@/lib/helpers";
import SignUpForm from "components/Auth/SignUpForm_old";
import { useStores } from "hooks/useStores";
import { observer } from "mobx-react";

const SignUp = observer(() => {
  const { profileStore, authStore } = useStores();
  const { setIsProfileWizardActive } = profileStore;

  const {
    sessionId,
    setUserId,
    guestId, 
    utmString
  } = useContext(SessionContext);

  const router = useRouter();

  const handleSignup = (values, actions) => {
    const { setSubmitting, setErrors } = actions;

    const signUpStatus = authStore.signUp(values);

    signUpStatus.then((response) => {
      // If there are errors, set them on the form
      if (response.data.errors) {
        setErrors(response.data.errors);
        setSubmitting(false);
      }

      // Otherwise if sign up was a success, login and handle that
      if (response.data.success) {
        const loginStatus = authStore.login({
          loginName: values.email,
          password: values.password,
        });

        loginStatus
          .then((response) => {
            authStore.setIsAuthActive(false);
            setIsProfileWizardActive(true);
            setSubmitting(false);
            setUserId(authStore.currentUser.id);

            pushEvent("free_account_created", {
              userData: {
                identifier: authStore.currentUser.id,
                email: authStore.currentUser.email,
                firstName: authStore.currentUser.firstName,
                lastName: authStore.currentUser.lastName,
                userTier: getUserTierFromPlan(authStore.currentUser.plan)
              },
              metaData: {
                sessionId,
                eventTimestamp: Date.now(),
                platform: "website",
                device: getDevice(),
                pagePath: router.asPath,
                referrer: document.referrer,
                guestId,
                utmString
              }
            });
            pushEvent("account_signup", {
              user_id: authStore.currentUser.id,
            });
          })
          .catch((error) => {
            setSubmitting(false);
          });
      }
    });
  };

  return <SignUpForm onSubmit={handleSignup} />;
});

export default SignUp;
