import SignUp from "@/components/Auth/SignUp";
import ForgottenPasswordForm from "@/components/ForgottenPasswordForm";
import Login from "@/components/Login";
import { useAdContext } from "@/contexts/AdContext";
import useApp from "@/hooks/useApp";
import useSubscriptionPermission from "@/hooks/useSubscriptionPermission";
import getCommonStaticProps from "@/lib/getCommonStaticProps";
import clsx from "clsx";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";

export async function getStaticProps({ params, previewData, preview }) {
  // Fetch common static properties
  const commonStaticProps = await getCommonStaticProps();

  return {
    props: { ...commonStaticProps },
    revalidate: process.env.VERCEL_ENV !== "production" ? 1 : 3600,
  };
}

const LoginPage = ({}) => {
  const { plan } = useSubscriptionPermission(null);
  const router = useRouter();
  const [screen, setScreen] = useState("login"); // login / reset / sign up

  let isMobApp = useApp();

  // Disable ads
  const { setShowAds } = useAdContext();
  useEffect(() => setShowAds(false));

  // Redirect once logged in
  useEffect(() => {
    if (plan !== "fetching" && plan !== 0) {
      // Get previousUrl from local storage, or if it doesn't exist default to home as a safe default
      const previousUrl =
        sessionStorage.getItem("previousUrlForce") ||
        sessionStorage.getItem("previousUrl") ||
        "/";

      // If this is not a paid user, always force them to the plus dashboard
      if (!previousUrl.includes("/campaign") && plan !== 2) {
        router.push("/premium#sign-up");
        sessionStorage.removeItem("previousUrl");
        sessionStorage.removeItem("previousUrlForce");
        return;
      }
      // If they came from the plus homepage, redirect them to the appropriate next step
      if (previousUrl == "/premium") {
        if (plan === 2) {
          router.push("/");
        } else {
          router.push("/premium#sign-up");
        }
        sessionStorage.removeItem("previousUrl");
        sessionStorage.removeItem("previousUrlForce");
      } else {
        router.push(previousUrl);
        sessionStorage.removeItem("previousUrl");
        sessionStorage.removeItem("previousUrlForce");
      }
    }
  }, [plan]);

  // Allows for hotlinking to a specific screen on the flow
  useEffect(() => {
    const hash = window.location.hash;
    if (hash === "#reset") {
      setScreen("reset");
    } else if (hash === "#sign-up") {
      setScreen("sign-up");
    } else {
      setScreen("login");
    }
  }, []);

  return (
    <>
      <div className="relative flex min-h-[calc(100vh-64px)] justify-center bg-zinc-800 pt-6 md:items-center md:pt-0 lg:min-h-[calc(100vh-96px)]">
        {!isMobApp && (
          <video
            autoPlay
            muted
            playsInline
            loop
            className="absolute left-0 top-0 h-full w-full object-cover"
          >
            <source
              src="https://player.vimeo.com/progressive_redirect/playback/807532125/rendition/1080p/file.mp4?loc=external&signature=59181ec346a52705d8631f122b709dda36dd4419269ed1b4b5e96be07178d5c5"
              type="video/mp4"
            />
          </video>
        )}

        <div className="relative z-50 w-full px-4 md:p-10">
          <div className="mx-auto w-full rounded-2xl bg-white p-6 md:w-112 md:p-10">
            <nav>
              <ul className="tab-list-sm">
                <a
                  href="/signin"
                  onClick={(e) => {
                    e.preventDefault();
                    setScreen("login");
                  }}
                  className={clsx(`tab flex-1`, {
                    "bg-transparent": screen === "sign-up",
                    "bg-white": screen !== "sign-up",
                  })}
                >
                  Login
                </a>
                {!isMobApp && (
                  <a
                    href="/signin"
                    onClick={(e) => {
                      e.preventDefault();
                      setScreen("sign-up");
                    }}
                    className={clsx(`tab flex-1`, {
                      "bg-transparent": screen !== "sign-up",
                      "bg-white": screen === "sign-up",
                    })}
                  >
                    Sign Up
                  </a>
                )}
              </ul>
            </nav>
            {screen === "login" && (
              <>
                <div className={clsx("pt-4")}>
                  <Login darkMode={false} />
                </div>
                <p className="mt-4 text-center font-body text-sm">
                  <a
                    href="#reset"
                    onClick={() => {
                      setScreen("reset");
                    }}
                    className="text-zinc-600 underline underline-offset-2 hover:text-zinc-800"
                  >
                    I have forgotten my password
                  </a>
                </p>
              </>
            )}
            {screen === "reset" && (
              <>
                <ForgottenPasswordForm />
                <p className="mt-4 text-center text-sm">
                  <a
                    href="#"
                    onClick={() => {
                      setScreen("login");
                    }}
                    className="text-zinc-600 underline hover:text-zinc-800"
                  >
                    I have remembered my password
                  </a>
                </p>
              </>
            )}
            {screen === "sign-up" && (
              <>
                <p className="mb-4 space-x-2 border-b py-6 text-center font-body text-sm">
                  <sspan>Already have a Mob account?</sspan>
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      setScreen("login");
                    }}
                    className={clsx(
                      "underline underline-offset-2 hover:opacity-75"
                    )}
                  >
                    Login to upgrade
                  </a>
                </p>
                <SignUp />
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginPage;
