import Button from "components/Button";
import Form from "components/Form";
import TextField from "components/TextField";
import { Formik } from "formik";
import { AnimatePresence, motion } from "framer-motion";
import { useStores } from "hooks/useStores";

const SignUpForm = ({ onSubmit = () => null, account = { firstName: "", lastName: "", email: "", password: "" } }) => {
  const { profileStore } = useStores();

  return (
    <Formik
      initialValues={{
        firstName: account.firstName,
        lastName: account.lastName,
        email: account.email,
        emailConfirm: account.email,
        password: "",
      }}
      onSubmit={onSubmit}
      validationSchema={profileStore.validationSchema}
      validateOnBlur={false}
    >
      {({ isSubmitting, handleReset, handleSubmit, status }) => (
        <Form
          className="Form"
          handleReset={handleReset}
          handleSubmit={handleSubmit}
          actions={[
            <Button
              key="submit"
              label={isSubmitting ? "Please wait..." : "Sign Up"}
              type="submit"
              isDisabled={isSubmitting}
              isFull
              theme="light"
            />,
          ]}
        >
          <TextField
            name="firstName"
            label="First name"
            placeholder="Enter your first name"
            type="text"
            //  focusOnMount
          />
          <TextField
            name="lastName"
            label="Last name"
            placeholder="Enter your last name"
            type="text"
          />
          <TextField
            name="email"
            label="Email"
            placeholder="Enter your email"
            type="email"
          />
          <TextField
            name="emailConfirm"
            label="Confirm your email"
            placeholder="Enter your email"
            type="email"
          />

          <TextField
            name="password"
            label="Password"
            placeholder="Enter a password"
            type="password"
          />

          <p className="py-3 text-center font-body text-sm text-zinc-800">
            By signing up you agree to the Mob{" "}
            <a
              href="/mob-plus-terms-and-conditions"
              className="underline underline-offset-2 hover:opacity-75"
              target="_blank"
            >
              Terms and Conditions
            </a>
          </p>

          <AnimatePresence>
            {status && (
              <motion.div
                className="Form__status"
                variants={{
                  hidden: { opacity: 0, height: 0 },
                  show: { opacity: 1, height: "auto" },
                }}
                initial="hidden"
                animate="show"
                exit="hidden"
              >
                {status}
              </motion.div>
            )}
          </AnimatePresence>
        </Form>
      )}
    </Formik>
  );
};

export default SignUpForm;
