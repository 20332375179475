import Button from "components/Button";
import Form from "components/Form";
import TextField from "components/TextField";
import { Formik } from "formik";
import { AnimatePresence, motion } from "framer-motion";
import { useStores } from "hooks/useStores";
import { observer } from "mobx-react";
import { useState } from "react";
import * as Yup from "yup";

const ForgottenPasswordForm = observer(() => {
  const [isSuccessful, setIsSuccessful] = useState(false);

  const { authStore } = useStores();

  const validationSchema = Yup.object().shape({
    loginName: Yup.string()
      .email("Please enter a valid email address")
      .required("Your email address is required"),
  });

  const onSubmit = (
    values,
    { setSubmitting, setStatus, resetForm, setFieldValue, status }
  ) => {
    const forgottenPasswordStatus = authStore.forgottenPassword(values);

    forgottenPasswordStatus
      .then((response) => {
        if (response.data.success) {
          setIsSuccessful(true);
          resetForm({
            loginName: "",
          });
        }
      })
      .catch((error) => {
        setIsSuccessful(false);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <div className="ForgottenPasswordForm">
      <Formik
        initialValues={{ loginName: "" }}
        onSubmit={onSubmit}
        validateOnBlur={false}
        validationSchema={validationSchema}
      >
        {({ isSubmitting, handleReset, handleSubmit }) => (
          <Form
            className="Form"
            handleReset={handleReset}
            handleSubmit={handleSubmit}
            actions={[
              <Button
                key={1}
                label={isSubmitting ? "Submitting..." : "Submit"}
                type="submit"
                isDisabled={isSubmitting || isSuccessful}
                isFull
                theme="light"
              />,
            ]}
          >
            <TextField
              name="loginName"
              label="Email"
              placeholder="Enter your email"
              type="text"
            />

            <AnimatePresence>
              {isSuccessful && (
                <motion.div
                  className="Form__status"
                  variants={{
                    hidden: { opacity: 0, height: 0 },
                    show: { opacity: 1, height: "auto" },
                  }}
                  initial="hidden"
                  animate="show"
                  exit="hidden"
                >
                  {`You'll receive an email with instructions on how to set
                  your new password`}
                </motion.div>
              )}
            </AnimatePresence>
          </Form>
        )}
      </Formik>
    </div>
  );
});

export default ForgottenPasswordForm;
